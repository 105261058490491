export const rxAuditHomeBanner_style = {
  bannerTextSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  cardSx: {
    boxShadow: 'none',
    height: '88px',
    backgroundColor: '#FFD8BE',
    overflow: 'inherit',
    position: 'relative',
    // marginTop: '16px',
    borderRadius: '8px',
  },
  imgcontentSx: {
    position: 'absolute',
    top: '-23px',
    zIndex: 10,
    p: 2,
    // "&:last-child": {
    //   paddingBottom: 16,
    // },
  },
  bannerSubTextSx: {
    fontSize: '14px',
    textAlign: 'left',
  },
};
