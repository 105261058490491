/* eslint-disable no-sequences */
/* eslint-disable react/no-children-prop */

import { Layout } from '@components/doctor';
import { rxAuditRoutes } from '@hc/routes';
import { useDoctorProfile, useRxNewAndHistory, useViewPort } from '@hc/store';
import {
  Button,
  CloseIcon,
  DataTabs,
  Drawer,
  Input,
  NoAppointments,
  SearchIcon,
} from '@hc/ui/atoms';
import {
  ClinicalEmptyState,
  DateChanger,
  LogoutModal,
  RxAuditHomeBanner,
  RxAuditListCard,
} from '@hc/ui/components';
import { BackofficeStepper } from '@hc/ui/components/backoffice/backofficeStepper';
import { StepperDrawer } from '@hc/ui/components/backoffice/stepperDrawer';
import { AppointmentUi } from '@hc/ui/components/doctor/appointmentui';
import { DoctorVerification } from '@hc/ui/components/doctor/doctorVerification';
import { getAuthData, localStorageKeys, queryClient } from '@hc/utils';
import { Box, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { endOfDay, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';
import { homepage_style } from './style';

export default function Home() {
  const [tabIndex, setTabIndex] = useState('1');
  const navigate = useNavigate();
  const data = getAuthData();
  const [onsearchValue, setOnsearchValue] = useState('');
  const [enableLoading, setEnableLoading] = useState('');
  const [logDrawerOpen, setLogDrawerOpen] = useState(false);
  const [dates, setDates] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  });
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);

  const {
    getAllListData,
    rxNewAndHistoryState,
    getDataById,
    clearStateValue,
    getHistoryLogData,
    loading,
  } = useRxNewAndHistory(
    (state) => ({
      getHistoryLogData: state.getHistoryLogData,
      clearStateValue: state.clearStateValue,
      getDataById: state.getDataById,
      getAllListData: state.getAllListData,
      rxNewAndHistoryState: state.rxNewAndHistoryState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const breaks = [
    {
      value: '1',
      label: 'New request',
      count: ` (${rxNewAndHistoryState?.newList?.length ?? 0})`,
    },
    {
      value: '2',
      label: 'History',
      count: ` (${rxNewAndHistoryState?.history?.length ?? 0})`,
    },
  ];

  const { profile, getDoctorProfileData } = useDoctorProfile((state) => ({
    profile: state.profile,
    getDoctorProfileData: state.getDoctorProfileData,
  }));

  const setProfile = () => {
    navigate(rxAuditRoutes?.profile);
  };

  const [openModel, setModel] = useState(false);
  const LogoutFun = () => {
    setModel(true);
  };

  const handleLogout = () => {
    toast.success('Signed Out Successfully');
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleCancleModel = () => {
    setModel(false);
  };

  const verifyDoctorMenuItems = [
    {
      name: 'View Profile',
      onclick: setProfile,
    },
    {
      name: 'Logout',
      onclick: LogoutFun,
    },
  ];

  const tabs = [
    {
      value: '1',
      label: 'New request',
      count: (
        <Avatar
          alt=""
          sx={{
            backgroundColor: tabIndex === '1' ? '#007965' : 'text.primary',
            color: '#fff',
            ...homepage_style.numberCountSx,
          }}
        >
          {`${
            rxNewAndHistoryState?.newList?.length > 0 &&
            rxNewAndHistoryState?.newList?.length < 10
              ? `0${rxNewAndHistoryState?.newList?.length ?? '0'}`
              : rxNewAndHistoryState?.newList?.length ?? '0'
          }`}
        </Avatar>
      ),
    },
    {
      value: '2',
      label: 'History',
      count: (
        <Avatar
          alt=""
          sx={{
            backgroundColor: tabIndex === '2' ? '#007965' : 'text.primary',
            color: '#fff',
            ...homepage_style.numberCountSx,
          }}
        >
          {`${
            rxNewAndHistoryState?.history?.length > 0 &&
            rxNewAndHistoryState?.history?.length < 10
              ? `0${rxNewAndHistoryState?.history?.length ?? '0'}`
              : rxNewAndHistoryState?.history?.length ?? '0'
          }`}
        </Avatar>
      ),
    },
  ];

  const getInitialData = async (
    search,
    offset,
    limit,
    type,
    startDate,
    endDate,
  ) => {
    queryClient.invalidateQueries({
      queryKey: ['getAllRxAuditList'],
    });
    await getAllListData(search, offset, limit, type, startDate, endDate);
    await getDoctorProfileData();
  };

  const onViewRequest = async (val, index) => {
    setEnableLoading(index);
    if (tabIndex === '1') {
      queryClient.invalidateQueries({
        queryKey: ['getRxAuditId'],
      });
      const result = await getDataById(val?.id ?? '');
      if (result?.code === 200) {
        navigate(rxAuditRoutes?.summary);
      }
    } else {
      queryClient.invalidateQueries({
        queryKey: ['getRxAuditLogData'],
      });

      const result = await getHistoryLogData(val?.id ?? '');
      if (result?.code === 200) {
        setLogDrawerOpen(true);
      }
    }
  };

  const onCloseLogDrawer = () => {
    setLogDrawerOpen(false);
  };

  const onTabChnage = (tab) => {
    setTabIndex(tab);
    sessionStorage.setItem(localStorageKeys.doctorPage, tab);
  };

  const onSearch = (value) => {
    setOnsearchValue(value.target?.value);
    getInitialData(
      value.target?.value,
      offset,
      limit,
      tabIndex,
      dates?.startDate,
      dates?.endDate,
    );
  };

  const onClickToday = async () => {
    // debugger
    queryClient?.invalidateQueries({
      queryKey: ['getAllRxAuditList'],
    });
    const date = new Date().setDate(new Date().getDate());
    setDates({
      startDate: startOfDay(date),
      endDate: endOfDay(date),
    });
    await getAllListData(
      onsearchValue,
      offset,
      limit,
      tabIndex,
      startOfDay(new Date()),
      endOfDay(new Date()),
    );
  };

  const onDayChange = async (val, num) => {
    if (val === 'add') {
      const day = dates?.startDate;
      const date = day.setDate(day.getDate() + num);
      const result = {
        startDate: startOfDay(date),
        endDate: endOfDay(date),
      };
      setDates(result);
      // setSearchDates(result);
      queryClient.invalidateQueries({
        queryKey: ['getAllRxAuditList'],
      });
      await getAllListData(
        onsearchValue,
        offset,
        limit,
        tabIndex,
        result?.startDate,
        result?.endDate,
      );
    } else {
      const day = dates?.startDate;
      const date = day.setDate(day.getDate() - num);
      const result = {
        startDate: startOfDay(date),
        endDate: endOfDay(date),
      };
      setDates(result);
      // setSearchDates(result);
      queryClient.invalidateQueries({
        queryKey: ['getAllRxAuditList'],
      });
      await getAllListData(
        onsearchValue,
        offset,
        limit,
        tabIndex,
        result?.startDate,
        result?.endDate,
      );
    }
  };

  const handleSearchClear = () => {
    setOnsearchValue('');
  };

  useEffect(() => {
    clearStateValue();
    getInitialData(
      onsearchValue,
      offset,
      limit,
      tabIndex,
      dates?.startDate,
      dates?.endDate,
    );
    localStorage.setItem(localStorageKeys.rxAuditVersion, version);
  }, []);

  useEffect(() => {
    const date = new Date().setDate(new Date().getDate());
    setDates({
      startDate: startOfDay(date),
      endDate: endOfDay(date),
    });
    const result = {
      startDate: startOfDay(date),
      endDate: endOfDay(date),
    };
    getInitialData(
      onsearchValue,
      offset,
      limit,
      tabIndex,
      result?.startDate,
      result?.endDate,
    );
  }, [tabIndex]);

  // doctorPage
  return (
    <Box sx={homepage_style.rootSx}>
      <Layout
        isDoctorVerfied={data?.is_verified}
        appBarFirstChild={
          <Box>
            {data?.is_verified && (
              <Box sx={{ overflowY: 'scroll', mt: 1 }}>
                <DataTabs
                  isResponsive
                  tabs={breaks}
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  totalStyle={homepage_style.tabStyleSx}
                  underTabSx={homepage_style.underTabStyleSx}
                  tabStyle={homepage_style.tabSx}
                  containerStyle={{
                    width: 425,
                    overflowY: 'scroll',
                  }}
                />
              </Box>
            )}
          </Box>
        }
        appBarSecChild={
          <Box>
            {data?.is_verified && (
              <Box>
                <Box sx={{ overflowY: 'scroll', mt: 1 }}>
                  <Input
                    placeholder="Search"
                    textFieldStyle={{
                      '& .MuiOutlinedInput-root': {
                        py: 0.3,
                        color: '#C5C5C5',
                        '& fieldset': {
                          borderRadius: '8px',
                          border: '1.5px solid',
                          borderColor: 'border.main',
                        },
                        '&:hover fieldset': {
                          borderColor: 'border.hover',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'border.focus',
                          color: '#0E1824',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        fontSize: '14px',
                        color: '#0E1824',
                      },
                    }}
                    onChange={onSearch}
                    value={onsearchValue}
                    startAdornment={
                      !onsearchValue?.length > 0 && <SearchIcon />
                    }
                    endAdornment={
                      onsearchValue?.length > 0 && (
                        <Box
                          onClick={handleSearchClear}
                          sx={{ cursor: 'pointer' }}
                        >
                          <CloseIcon />
                        </Box>
                      )
                    }
                  />
                </Box>

                <Stack direction="row" spacing={1} mt={1.2} alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onClickToday();
                    }}
                    sx={homepage_style.todaybuttonSx}
                  >
                    <Typography
                      component="span"
                      color="text.primary"
                      fontSize="14px"
                      fontWeight="500"
                    >
                      Today
                    </Typography>
                  </Button>

                  <DateChanger
                    rightIcon
                    leftIcon
                    startDate={dates?.startDate}
                    readOnly
                    onDayChange={onDayChange}
                    iconStyle={{ color: 'common.black' }}
                  />
                </Stack>
              </Box>
            )}
          </Box>
        }
        children={
          <Box height={isMobilePort && '100vh'} mb={isMobilePort && 12}>
            <Box sx={{ minHeight: '95vh', mt: 8.5 }}>
              {data?.is_verified ? (
                <Box>
                  {!isMobilePort && (
                    <RxAuditHomeBanner
                      doctorName={profile?.name}
                      newRequestCount={rxNewAndHistoryState?.newListCount}
                      loading={loading}
                    />
                  )}

                  <Box
                    sx={{
                      mt: 2.5,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '12px',
                      // height: '81vh',
                      overflow: 'scroll',
                      boxShadow: '0px 1px 10px #0000000A',
                      position: 'relative',
                    }}
                  >
                    {!isMobilePort && (
                      <Box
                        sx={{
                          position: 'sticky',
                          top: 0,
                          zIndex: 100,
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        <DataTabs
                          tabIndex={tabIndex}
                          setTabIndex={(param) => onTabChnage(param)}
                          totalStyle={homepage_style.tabStyleSx}
                          underTabSx={homepage_style.underTabStyleSx}
                          tabStyle={homepage_style.tabSx}
                          tabs={tabs}
                          containerStyle={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          onSearchVal={onSearch}
                          count
                          searchbar
                        />
                      </Box>
                    )}

                    <Box sx={{ px: 2, mt: isMobilePort && '146px' }}>
                      {!isMobilePort && (
                        <AppointmentUi
                          isRxAudit
                          tabIndex={tabIndex}
                          enableLoading={enableLoading}
                          setEnableLoading={setEnableLoading}
                          offset={offset}
                          setOffset={setOffset}
                          setSearchDates={setDates}
                          limit={limit}
                          onRxViewRequest={onViewRequest}
                          search={onsearchValue}
                        />
                      )}
                      {isMobilePort && (
                        <Box>
                          {tabIndex === '1' ? (
                            rxNewAndHistoryState?.newList?.length > 0 ? (
                              rxNewAndHistoryState?.newList?.length > 0 &&
                              rxNewAndHistoryState?.newList.map((obj, i) => (
                                <Box key={i}>
                                  <RxAuditListCard
                                    data={obj}
                                    onRequestView={() => onViewRequest(obj)}
                                  />
                                </Box>
                              ))
                            ) : (
                              <ClinicalEmptyState
                                IconRequired={<NoAppointments />}
                                emptyStateStyle={{ mt: 6 }}
                                subHead="No new requests"
                                subHeadStyle={homepage_style.subHeadSx}
                              />
                            )
                          ) : rxNewAndHistoryState?.history?.length > 0 ? (
                            rxNewAndHistoryState?.history?.length > 0 &&
                            rxNewAndHistoryState?.history.map((obj, i) => (
                              <Box key={i}>
                                <RxAuditListCard
                                  data={obj}
                                  isHistory
                                  onRequestView={() => onViewRequest(obj)}
                                />
                              </Box>
                            ))
                          ) : (
                            <ClinicalEmptyState
                              IconRequired={<NoAppointments />}
                              emptyStateStyle={{ mt: 6 }}
                              subHead="No histories"
                              subHeadStyle={homepage_style.subHeadSx}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <DoctorVerification doctorName={data?.name} isMobilePort />
                </Box>
              )}
            </Box>

            {openModel && (
              <LogoutModal
                isModalOpen={openModel}
                handleClose={handleCancleModel}
                handleDelete={handleLogout}
              />
            )}

            {!isMobilePort && (
              <StepperDrawer
                headerName="Log"
                stepperDrawerOpen={logDrawerOpen}
                closeStepperDrawer={onCloseLogDrawer}
                stepperData={
                  Array.isArray(rxNewAndHistoryState?.logData) &&
                  rxNewAndHistoryState?.logData?.length > 0
                    ? rxNewAndHistoryState?.logData
                    : []
                }
              />
            )}

            {/* <Drawer
              show={openInfoDrawer}
              onCloseDrawer={() => onCloseInfoDrawer()}
              header={infoDrawerContent?.type}
              height="50vh"
              headerStyle={{ fontWeight: 600 }}
              footer={
                <Box>
                  <Button
                    buttonStyle={{
                      bgcolor: '#DCF5F1',
                      color: 'primary.main',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#DCF5F1',
                        color: 'primary.main',
                      },
                    }}
                    onClick={() => onCloseInfoDrawer()}
                  >
                    Close
                  </Button>
                </Box>
              }
            >

            </Drawer> */}
            {isMobilePort && (
              <Drawer
                show={logDrawerOpen}
                onCloseDrawer={onCloseLogDrawer}
                // anchor="right"
                isCloseIconRequired
                header="Log"
                footer={
                  <Box>
                    <Button
                      buttonStyle={{
                        bgcolor: '#DCF5F1',
                        color: 'primary.main',
                        textTransform: 'capitalize',
                        '&:hover': {
                          bgcolor: '#DCF5F1',
                          color: 'primary.main',
                        },
                      }}
                      onClick={() => onCloseLogDrawer()}
                    >
                      Close
                    </Button>
                  </Box>
                }
                headerStyle={homepage_style.headerSx}
              >
                <Box sx={homepage_style.boxRootSx}>
                  <BackofficeStepper
                    stepperData={
                      Array.isArray(rxNewAndHistoryState?.logData) &&
                      rxNewAndHistoryState?.logData?.length > 0
                        ? rxNewAndHistoryState?.logData
                        : []
                    }
                  />
                </Box>
              </Drawer>
            )}
          </Box>
        }
        menuItems={verifyDoctorMenuItems}
        isRxAudit
      />
    </Box>
  );
}
