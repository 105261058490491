/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable func-names */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { doctorRoutes, rxAuditRoutes } from '@hc/routes';
import { useRxNewAndHistory, useViewPort } from '@hc/store';
import {
  Button,
  CloseIcon,
  ConfirmAppointmentIcon,
  CustomTextArea,
} from '@hc/ui/atoms';
import {
  FilterTab,
  PatientSummary,
  Screenlayout,
  SummaryPrescription,
} from '@hc/ui/components';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { summary_style } from './style';

export default function Summary(props) {
  const { className = '' } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkMulitiWindow, setCheckMulitiWindow] = useState(false);
  const [isApprove, setIsApprove] = useState(0);

  const [tabIndex, setTabIndex] = useState(0);
  const filterList = ['Patient Info', 'Summary'];

  const {
    rxNewAndHistoryState,
    setApproveOrReject,
    updateStateValue,
    clearStateValue,
    loading,
  } = useRxNewAndHistory(
    (state) => ({
      clearStateValue: state.clearStateValue,
      updateStateValue: state.updateStateValue,
      setApproveOrReject: state.setApproveOrReject,
      rxNewAndHistoryState: state.rxNewAndHistoryState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const issueOrApproveModalClose = () => {
    setIsModalOpen(false);
  };

  const onCloseApporveModel = () => {
    issueOrApproveModalClose();
    clearStateValue();
    navigate(rxAuditRoutes?.home);
  };

  const issueOrApproveModalOpen = async (value) => {
    if (value) {
      const result = await setApproveOrReject(true);
      if (result?.code === 200) {
        setIsApprove(value);
        setIsModalOpen(true);
      }
    } else {
      setIsApprove(value);
      setIsModalOpen(true);
    }
  };

  const onRejectClick = async () => {
    const result = await setApproveOrReject(false);
    if (result?.code === 200) {
      clearStateValue();
      navigate(rxAuditRoutes?.home);
    }
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onTextAreaChange = (value) => {
    updateStateValue('issueRemark', value);
  };

  useEffect(() => {
    if (!location?.state?.isViewSummary) {
      const bc = new BroadcastChannel('test_channel');
      bc.postMessage('This is a test message.');
      bc.onmessage = function (ev) {
        if (ev?.isTrusted === true) {
          setCheckMulitiWindow(true);
        } else {
          setCheckMulitiWindow(false);
        }
      };
    }

    if (rxNewAndHistoryState?.auditData?.id?.length === 0) {
      navigate(rxAuditRoutes?.home);
    }
  }, []);

  return (
    <Screenlayout
      titleRequired
      title={`${rxNewAndHistoryState?.auditData?.patient_gender} , ${rxNewAndHistoryState?.auditData?.patient_age}`}
      className={`class ${className}`}
      backRequired
      fill="#E2E2E2"
      titleStyle={summary_style.titleSx}
      isDoctorAppbar
      notshowFooter={!isMobilePort}
      footer={
        <Grid container columnSpacing={2}>
          <Grid item xs={6} display="flex" justifyContent="end">
            <Button
              id="closeVisit"
              type="submit"
              sx={{
                p: isMobilePort ? '5px' : '5px 15px',
                boxShadow: 'none',
                borderRadius: '8px',
                fontSize: '14px',
                bgcolor: '#feedee',
                color: '#F44F5A',
                textTransform: 'capitalize',
                // width: '154px',
                '&:hover': {
                  bgcolor: '#feedee',
                  color: '#F44F5A',
                },
              }}
              color="primary"
              fontSize="14px"
              onClick={() => issueOrApproveModalOpen(false)}
            >
              Issue
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              id="closeVisit"
              type="submit"
              sx={{
                p: isMobilePort ? '5px' : '5px 15px',
                boxShadow: 'none',
                borderRadius: '8px',
                fontSize: '14px',
                // width: '154px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
              fontSize="14px"
              onClick={() => issueOrApproveModalOpen(true)}
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      }
      backIconStyle={summary_style.backIconSx}
      backRoute={doctorRoutes?.home}
      onRxIssueButtonClick={() => issueOrApproveModalOpen(false)}
      onRxApproveButtonClick={() => issueOrApproveModalOpen(true)}
      isRxAudit={!isMobilePort}
    >
      <Box mt="-7px">
        <Box>
          {!isMobilePort && (
            <Grid container display="flex">
              <Grid
                item
                sx={{ borderRight: '1px solid #E3E3E3' }}
                xs={isMobilePort ? 12 : 4}
                px={1.5}
              >
                <PatientSummary
                  isRxAudit
                  isRxAuditData={rxNewAndHistoryState?.auditData ?? {}}
                  summaryView
                />
              </Grid>

              <Grid xs={isMobilePort ? 12 : 8}>
                <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
                  <Box px={3} pb={3}>
                    <Box sx={summary_style.summaryHeaderSx}>
                      <Typography
                        sx={{
                          color: 'text.primary',
                          fontSize: '18px',
                          fontWeight: 600,
                        }}
                      >
                        Summary
                      </Typography>
                    </Box>

                    <SummaryPrescription
                      summaryView
                      isRxAudit
                      isRxAuditData={rxNewAndHistoryState?.auditData ?? {}}
                      summaryTitle="PRESCRIPTION"
                    />
                  </Box>

                  <Dialog
                    open={checkMulitiWindow}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      sx: { width: 650 },
                    }}
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Action cannot be done as the application still opens in
                        another tab. If you want to Continue refresh the page to
                        proceed.
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </Box>
              </Grid>
            </Grid>
          )}

          <Box sx={summary_style.rootSx}>
            <Modal open={isModalOpen} onClose={issueOrApproveModalClose}>
              {isApprove ? (
                <Box sx={summary_style.totalModalSx}>
                  <Box sx={summary_style.totalBoxSx}>
                    <Box mt={2}>
                      <Grid container rowSpacing={2}>
                        <Grid
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          item
                        >
                          <ConfirmAppointmentIcon />
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          display="flex"
                          justifyContent="center"
                        >
                          <Typography sx={summary_style.description}>
                            Drug Audit Approved!!
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          display="flex"
                          justifyContent="center"
                        >
                          <Button
                            sx={summary_style.closebuttonSx}
                            onClick={onCloseApporveModel}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box sx={summary_style.totalIssueModalSx}>
                  <Box sx={summary_style.totalIssueBoxSx}>
                    <Box mt={2}>
                      <Grid
                        container
                        height={300}
                        display="flex"
                        rowSpacing={2}
                      >
                        <Grid
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          item
                        >
                          <Box>
                            <Typography sx={summary_style.description}>
                              Reason for issue
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton onClick={issueOrApproveModalClose}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid xs={12} item>
                          <Typography fontSize={12} color="#262C33">
                            Reason
                          </Typography>
                          <CustomTextArea
                          data_testId='reason'
                            value={rxNewAndHistoryState?.issueRemark ?? ''}
                            onChange={(event) =>
                              onTextAreaChange(event?.target?.value)
                            }
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          display="flex"
                          justifyContent="end"
                          alignSelf="end"
                        >
                          <Button
                            sx={{
                              p: isMobilePort ? '5px' : '5px 15px',
                              boxShadow: 'none',
                              borderRadius: '8px',
                              fontSize: '14px',
                              bgcolor: '#DCF5F1',
                              color: '#007965',
                              mr: 2,
                              width: '150px',
                              textTransform: 'capitalize',
                              '&:hover': {
                                bgcolor: '#DCF5F1',
                                color: '#007965',
                              },
                            }}
                            onClick={issueOrApproveModalClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              p: isMobilePort ? '5px' : '5px 15px',
                              boxShadow: 'none',
                              borderRadius: '8px',
                              fontSize: '14px',
                              width: '150px',
                              textTransform: 'capitalize',
                            }}
                            loading={loading}
                            onClick={onRejectClick}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
            </Modal>
          </Box>

          {isMobilePort && (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', px: 3 }}>
                <Box p={1} width="100%">
                  <Box
                    sx={{
                      backgroundColor: '#EDEFF2',
                      borderRadius: '10px',
                      height: '40px',
                      width: '100%',
                    }}
                  >
                    <FilterTab
                      filterList={filterList}
                      handleChange={handleChange}
                      value={tabIndex}
                      filterTabRootStyle={summary_style.filterTabRootSx}
                      toggleStyle={{ height: '40px' }}
                    />
                  </Box>
                </Box>
              </Box>
              {tabIndex === 0 && (
                <Box px={3} pb={12}>
                  <PatientSummary
                    isRxAudit
                    isRxAuditData={rxNewAndHistoryState?.auditData ?? {}}
                    summaryView
                  />
                </Box>
              )}
              {tabIndex === 1 && (
                <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
                  <Box px={3} pb={3}>
                    <SummaryPrescription
                      summaryView
                      isRxAudit
                      isRxAuditData={rxNewAndHistoryState?.auditData ?? {}}
                      summaryTitle="PRESCRIPTION"
                    />
                  </Box>

                  <Dialog
                    open={checkMulitiWindow}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      sx: { width: 650 },
                    }}
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Action cannot be done as the application still opens in
                        another tab. If you want to Continue refresh the page to
                        proceed.
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Screenlayout>
  );
}

Summary.propTypes = {
  className: PropTypes.object,
};
