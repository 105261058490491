/* eslint-disable no-lonely-if */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { getDateFormat } from '@hc/dayjs';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { endOfDay, startOfDay } from 'date-fns';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useRxNewAndHistory = create((set, get) => ({
  rxNewAndHistoryState: {
    doctorName: '',
    newList: [],
    newListInitialState: [],
    newListCount: '',
    history: [],
    historyInitialState: [],
    historyCount: '',
    issueRemark: '',
    auditData: {},
    logData: [],
  },
  loading: false,
  error: null,

  getAllListData: async (
    search = '',
    offset = 0,
    limit = 10,
    type = '2',
    start_date = startOfDay(new Date()),
    end_date = endOfDay(new Date()),
  ) => {
    try {
      const { rxNewAndHistoryState } = get();
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getAllRxAuditList'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${
              envConfig.api_url
            }/rx-audit/getAll?search=${search}&limit=${limit}&offset=${offset}&type=${
              type === '2' ? 'history' : 'new'
            }&start_date=${start_date}&end_date=${end_date}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        if (type === '1') {
          // const resArr =
            // rxNewAndHistoryState?.newList?.length > 0
            //   ? [...rxNewAndHistoryState?.newList, ...data?.results]
            //   : [...data?.results];
          set(() => ({
            loading: false,
            rxNewAndHistoryState: {
              ...rxNewAndHistoryState,
              newList: data?.results ?? [],
              newListInitialState: data?.results ?? [],
              newListCount: data?.rx_audit_count,
            },
          }));
        } else {
          // const resArr =
            // rxNewAndHistoryState?.history?.length > 0
            //   ? [...rxNewAndHistoryState?.history, ...data?.results]
            //   : [...data?.results];
          set(() => ({
            loading: false,
            rxNewAndHistoryState: {
              ...rxNewAndHistoryState,
              history: data?.results ?? [],
              historyInitialState: data?.results ?? [],
              historyCount: data?.rx_audit_count,
            },
          }));
        }
      } else {
        set(() => ({
          loading: false,
        }));
      }
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getDataById: async (rxAuditId) => {
    try {
      const { rxNewAndHistoryState } = get();
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getRxAuditId'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/rx-audit/request/view?rx_audit_id=${rxAuditId}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({
          loading: false,
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            auditData: data ?? {},
          },
        });
      }
      return status;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getHistoryLogData: async (rxAuditId) => {
    try {
      const { rxNewAndHistoryState } = get();
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getRxAuditLogData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/rx-audit/log/get?rx_audit_id=${rxAuditId}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        const value = [
          {
            backgroundColor: '#ffffe6',
            color: '#FACC15',
            title: data[0]?.status_name ?? '',
            subTitle: data[0]?.doctor_name ?? '',
            dateTime: data[0]?.at_time ?? '',
            dateTimeFormat: 'DD MMM YYYY, hh:mm A',
          },
          {
            backgroundColor: data[1]?.status_name === 'Raised Issue' ? '#FEEBEC': '#E8FCF0',
            color:data[1]?.status_name === 'Raised Issue' ? '#F44F5A': '#4CAF50',
            title: data[1]?.status_name  ?? '',
            subTitle: data[1]?.doctor_name ?? '',
            dateTime: data[1]?.at_time ?? '',
            dateTimeFormat: 'DD MMM YYYY, hh:mm A',
          },
        ];
        set({
          loading: false,
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            logData: value ?? [],
          },
        });
      }
      return status;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  setApproveOrReject: async (isApprove) => {
    try {
      const { rxNewAndHistoryState } = get();
      const payload = isApprove
        ? {
            rx_audit_id: rxNewAndHistoryState?.auditData?.id ?? '',
            status: '',
          }
        : {
            rx_audit_id: rxNewAndHistoryState?.auditData?.id ?? '',
            remarks: rxNewAndHistoryState?.issueRemark ?? '',
            status: '',
          };
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['setRxApproveOrReject'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            isApprove
              ? `${envConfig.api_url}/rx-audit/approve`
              : `${envConfig.api_url}/rx-audit/reject`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        toast.success(status?.message);
      }
      return status;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  setRxAuditFilter: (value, tabIndex) => {
    const { rxNewAndHistoryState } = get();
    let dataList = JSON.parse(
      JSON.stringify(
        tabIndex === '1'
          ? rxNewAndHistoryState?.newList
          : rxNewAndHistoryState?.history,
      ),
    );
    if (value === 'ASC' || value === true) {
      dataList?.sort(
        (objA, objB) =>
          new Date(objA.updated_at).getTime() -
          new Date(objB.updated_at).getTime(),
      );

      if (tabIndex === '1') {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            newList: dataList,
          },
        });
      } else {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            history: dataList,
          },
        });
      }
    }

    if (value === 'DESC' || value === false) {
      dataList?.sort(
        (objA, objB) =>
          new Date(objB.updated_at).getTime() -
          new Date(objA.updated_at).getTime(),
      );
      if (tabIndex === '1') {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            newList: dataList ?? [],
          },
        });
      } else {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            history: dataList ?? [],
          },
        });
      }
    }
  },

  onSearchVal: (value, tabIndex) => {
    const { rxNewAndHistoryState } = get();
    let dataCopy =
      tabIndex === '1'
        ? JSON.parse(JSON.stringify(rxNewAndHistoryState?.newList))
        : JSON.parse(JSON.stringify(rxNewAndHistoryState?.history));

    let Arr = dataCopy?.filter(function (val) {
      return val?.name
        ?.toLocaleLowerCase()
        .includes(value?.toLocaleLowerCase());
    });
    if (tabIndex === '1') {
      if (value) {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            newList: Arr,
          },
        });
      } else {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            newList: rxNewAndHistoryState?.newListInitialState ?? [],
          },
        });
      }
    } else {
      if (value) {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            history: Arr,
          },
        });
      } else {
        set({
          rxNewAndHistoryState: {
            ...rxNewAndHistoryState,
            history: rxNewAndHistoryState?.historyInitialState ?? [],
          },
        });
      }
    }
  },

  updateStateValue: (key, value) => {
    const { rxNewAndHistoryState } = get();
    set({
      rxNewAndHistoryState: {
        ...rxNewAndHistoryState,
        [key]: value,
      },
    });
  },

  clearStateValue: () => {
    const { rxNewAndHistoryState } = get();
    set({
      rxNewAndHistoryState: {
        ...rxNewAndHistoryState,
        doctorName: '',
        newList: [],
        newListInitialState: [],
        newListCount: '',
        history: [],
        historyInitialState: [],
        historyCount: '',
        issueRemark: '',
        auditData: {},
      },
      loading: null,
    });
  },
}));
