import { getDateFormat } from '@hc/dayjs';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ClockIcon, Doctor, RxAuditCardArrowIcon } from '../../../atoms';
import { rxAuditListCard_style } from './style';

function RxAuditListCard(props) {
  const {
    className = '',
    isHistory,
    data = {},
    onRequestView = () => {},
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...rxAuditListCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={rxAuditListCard_style?.cardRootSx}
        onClick={() => onRequestView()}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Avatar
                  sx={{ height: '44px', width: '44px', alignSelf: 'center' }}
                  src={data?.profilePic}
                >
                  {data?.doctor_profile?.first_name?.slice(0, 1) ?? 'A'}
                </Avatar>
              </Grid>
              <Grid item display="flex" xs={8}>
                <Box alignSelf="center">
                  <Box>
                    <Typography fontSize={14} fontWeight={500}>
                      {`${data?.patient_gender},${data?.patient_age} Yrs`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={12} color="#888888">
                      {data?.appointment_type}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="end">
                {!isHistory ? (
                  <RxAuditCardArrowIcon
                    rootStyle={{ height: '30px', width: '30px' }}
                  />
                ) : (
                  <Box
                    sx={
                      data?.master_rx_audit_status?.rx_audit_status_name ===
                      'Approved'
                        ? rxAuditListCard_style?.approveSx
                        : rxAuditListCard_style?.raisedSx
                    }
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={
                        data?.master_rx_audit_status?.rx_audit_status_name ===
                        'Approved'
                          ? '#4CAF50'
                          : '#F44F5A'
                      }
                    >
                      {data?.master_rx_audit_status?.rx_audit_status_name ===
                      'Raised Issue'
                        ? 'Issue'
                        : 'Approved'}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container pt={2}>
              <Grid item xs={6}>
                <Box display="flex">
                  <Doctor />
                  <Typography
                    fontSize={12}
                    ml={0.5}
                    color="#808080"
                  >{`${data?.doctor_profile?.first_name}`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex">
                  <ClockIcon />
                  <Typography
                    fontSize={12}
                    ml={0.5}
                    color="#808080"
                  >{`${getDateFormat(
                    data?.updated_at,
                    'DD MMM, YYYY',
                  )}  `}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

RxAuditListCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isHistory: PropTypes.bool,
  data: PropTypes.object,
  onRequestView: PropTypes.func,
};

export { RxAuditListCard };
