export const rxAuditListCard_style = {
  cardRootSx: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 1.5,
    mb: 2,
    cursor: 'pointer',
  },
  approveSx: {
    bgcolor: '#E8FCF0',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    px:0.5
  },
  raisedSx: {
    bgcolor: '#FEEBEC',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    px:0.5
  },
};
