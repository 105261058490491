import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import doctorCalender from '@assets/doctorCalender.png';
import { rxAuditHomeBanner_style } from './style';
import { Loader } from '../../../atoms';

function RxAuditHomeBanner(props) {
  const {
    className = '',
    rootStyle = {},
    doctorName,
    newRequestCount,
    loading,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...rxAuditHomeBanner_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {loading && (
        <Box justifyContent="center" display="flex">
          <Box alignSelf="center">
            <Loader />
          </Box>
        </Box>
      )}
      {!loading && doctorName?.length > 0 && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={rxAuditHomeBanner_style.cardSx}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item sm={2} xs={2}>
                  <Box sx={rxAuditHomeBanner_style.imgcontentSx}>
                    <img src={doctorCalender} alt="calendar" />
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={10}
                  xs={10}
                  sx={rxAuditHomeBanner_style.gridMobile}
                >
                  <Box p={2}>
                    <Typography
                      color="text.primary"
                      sx={rxAuditHomeBanner_style.bannerTextSx}
                    >
                      Welcome Dr. {doctorName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      sx={rxAuditHomeBanner_style.bannerSubTextSx}
                    >
                      You have{' '}
                      <span style={{ fontWeight: 600 }}>{newRequestCount}</span>{' '}
                      new request.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

RxAuditHomeBanner.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  doctorName: PropTypes.any,
  newRequestCount: PropTypes.any,
  loading: PropTypes.bool,
};

export { RxAuditHomeBanner };
