/* eslint-disable prefer-const */
import { HealthCirclesLogo } from '@hc/ui/atoms';
import { StepperForm } from '@hc/ui/components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { onboarding_style } from './style';

export default function Onboarding(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={onboarding_style.rootSx} className={`${className}`} {...rest}>
      <StepperForm vertical logo={<HealthCirclesLogo />} />
    </Box>
  );
}

Onboarding.propTypes = {
  className: PropTypes.string,
};
