export const summary_style = {
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
    // backgroundColor:
  },

  divideDottedSx: {
    my: 2,
    borderStyle: 'dashed',
    borderColor: 'divider',
  },
  dividerSx: {
    mx: 1.5,
    height: '25px',
    border: '0.5px solid',
    backgroundColor: 'grey.400',
    borderColor: 'grey.400',
  },
  summaryHeaderSx: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2.5,
  },
  summaryRootSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    bgcolor: '#F5F5F5',
    pt: 1,
    px: 1,
    pb: 1.2,
    borderRadius: 1,
  },
  filterTabRootSx: {
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '49%',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  viewSummaryTextSx: {
    cursor: 'pointer',
    color: 'primary.main',
    fontSize: '12px',
    textDecoration: 'underline',
    textAlign: 'left',
    font: ' normal normal 600 16px/25px ',
    textTransform: 'uppercase',
  },
  dialogtitleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },

  contentSx: {
    pt: 0,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },

  titleSx: {
    fontSize: 16,
    fontWeight: 600,
    color:'#000'
  },
  dialogSx: {
    width: { xs: '50vw', md: '30vw' },
  },
  description: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#0F0B11',
    textAlign: 'center',
  },
  closebuttonSx: {
    maxWidth: '120px',
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '2px solid #007965',
  },
  DeletebuttonSx: {
    ml: 2,
    maxWidth: '120px',
    bgcolor: '#F44F5A',
    color: 'white',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
    '&:focus': {
      bgcolor: '#F44F5A',
    },
    border: '2px solid #F44F5A',
  },

  totalModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: '0px solid',
    boxShadow: 24,
    backgrounColor: '#FFF',
    p: 3,
    outline: '0px',
  },
  totalBoxSx: {
    backgroundColor: 'common.white',
    px: 6,
    py: 2,
    borderRadius: '8px',
    width: '100%',
    minWidth: '426px',
    minHeight: '190px',
    margin: '0px auto',
  },
  totalIssueModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 50,
    height: '300px',
    border: '0px solid',
    boxShadow: 24,
    backgrounColor: '#FFF',
    p: 3,
    outline: '0px',
  },
  totalIssueBoxSx: {
    backgroundColor: 'common.white',
    px: 3,
    pb: 2,
    borderRadius: '8px',
    width: '100%',
    // minWidth: '526px',
    minHeight: '300px',
    margin: '0px auto',
  },

  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#Fff',
    border: '1px solid',
    borderColor: ' primary.main',
    color: 'primary.main',
  },
  applyBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F44F5A',
    border: '1px solid',
    borderColor: '#F44F5A',
    color: '#fff',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',

      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  centerIconSx: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
};
