/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import { useDoctorReport, useViewPort } from '@hc/store';
import { ViewSummaryReportsEmptyState } from '@hc/ui/atoms';
import {
  DoctorScreenLayout,
  PdfSkeleton,
  ReportViewer,
  RxGroupSkeleton,
  Screenlayout,
  ViewSummaryReport
} from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useSearchParams } from 'react-router-dom';
import { reportViewSummary_style } from './style';

export default function ReportViewSummary(props) {
  const { className = '' } = props;

  const location = useLocation();
  const [searchParams] = useSearchParams();
  // const [tabIndex, setTabIndex] = useState('0');

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { loading, getPatientReport, reportState } = useDoctorReport(
    (state) => ({
      reportState: state.reportState,
      getPatientReport: state.getPatientReport,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const initialData = async () => {
    const obj = location.state.data;
    await getPatientReport(obj);
  };

  const getAppointmentData = async (type, id) => {
    if (type === 'searchParams') {
      const obj = {
        user_profile_id: '',
        appointment_id: id?.split('/')?.[0] ?? '',
      };
      await getPatientReport(obj);
    } else {
      const obj = {
        user_profile_id: location?.state?.appointmentId
          ? ''
          : location?.state?.patientId ?? '',
        appointment_id: location?.state?.appointmentId ?? '',
      };
      await getPatientReport(obj);
    }
  };

  useEffect(() => {
    if (location?.state?.appointmentId) {
      getAppointmentData('location', '');
    } else {
      initialData();
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('appointmentId')?.length > 0) {
      getAppointmentData('searchParams', searchParams.get('appointmentId'));
    }
  }, [searchParams.get('appointmentId')]);

  return (
    <>
      {!isMobilePort && (
        <DoctorScreenLayout
          titleRequired
          title={location?.state?.data?.patient_profile?.name}
          className={`class ${className}`}
          backRequired
          titleStyle={reportViewSummary_style.titleSx}
          backIconStyle={reportViewSummary_style.backIconSx}
          leftChildren={
            <Box sx={reportViewSummary_style.leftSx}>
              <Box>
                <ViewSummaryReport isRxAudit />
              </Box>
            </Box>
          }
          rightChildren={
            <>
              {loading && <PdfSkeleton />}
              <Box sx={{ display: loading ? 'none' : '' }}>
                {reportState?.viewReportData?.length > 0 && <ReportViewer />}
                {reportState?.viewReportData?.length === 0 && (
                  <Box height="100vh" justifyContent="center" display="flex">
                    <Box alignSelf="center">
                      <Box pb={2} justifyContent="center" display="flex">
                        <ViewSummaryReportsEmptyState />
                      </Box>
                      <Typography fontSize={12} color="#888888">
                        Preview of PDF will appear here
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          }
          rightSideContainer={8}
          leftSideContainer={4}
        />
      )}

      {isMobilePort && (
        <Screenlayout
          title="Report View Summary"
          className={`${className}`}
          titleStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#0E1824',
          }}
          notshowFooter
          backRequired
          isDoctorAppbar
          childrenStyle={{
            minHeight: '60vh',
            pt: {
              xs: 10,
            },
          }}
        >
          {/* Symptoms */}
          <Box sx={reportViewSummary_style.viewReportSx}>
            {loading &&
              Array(5)
                .fill(0)
                .map((i) => <RxGroupSkeleton key={i} />)}
            <Box SX={loading ? { display: 'none' } : ''}>
              <ViewSummaryReport
                isRxAudit
                loading={loading}
                isMobileResponsive={isMobilePort}
              />
            </Box>
          </Box>
        </Screenlayout>
      )}
    </>
  );
}
ReportViewSummary.propTypes = {
  className: PropTypes.string,
};
