import { useDoctorOnboarding, useViewPort } from '@hc/store';
import DoctorOnboarding from '@hc/ui/assets/doctorOnboarding.png';
import PropTypes from 'prop-types';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { forgotpassword_style } from './style';

export default function ForgotPassword(props) {
  const { className = '', ...rest } = props;

  const {
    doctor,
    forgotPassword,
    updateOnboardingFielsError,
    isMailValidation,
    handleOnboardingDataChange,
    loading,
  } = useDoctorOnboarding((state) => ({
    doctor: state.doctor,
    forgotPassword: state.forgotPassword,
    updateOnboardingFielsError: state.updateOnboardingFielsError,
    isMailValidation: state.isMailValidation,
    handleOnboardingDataChange: state.handleOnboardingDataChange,
    loading: state.loading,
  }));

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  // Change State
  const changeState = (key, value) => {
    handleOnboardingDataChange(key, value);
  };

  const onGetLink = () => {
    if (isMailValidation() && doctor?.email?.length > 0) {
      // calling the forgotPassword api
      forgotPassword(doctor?.email);
    }
  };

  useEffect(() => {
    updateOnboardingFielsError(doctor?.email);
  }, []);

  return (
    <Box sx={forgotpassword_style.rootSx} className={`${className}`} {...rest}>
      <Grid container sx={forgotpassword_style.totalSx}>
        {/* Right Side */}
        <Grid
          item
          sm={isMobilePort ? 12 : 6}
          sx={forgotpassword_style.leftSideSx}
        >
          <Box sx={isMobilePort ? { background: '#000' } : ''}>
            <img
              src={DoctorOnboarding}
              alt=""
              style={
                isMobilePort
                  ? forgotpassword_style.rightImgMobileSx
                  : forgotpassword_style.rightImgSx
              }
            />
          </Box>
        </Grid>
        {/* Left Side */}
        <Grid
          item
          xs={isMobilePort ? 12 : 6}
          sm={isMobilePort ? 12 : 6}
          sx={forgotpassword_style.rightSideSx}
        >
          <Stack
            p={5}
            spacing={2}
            sx={
              isMobilePort
                ? forgotpassword_style.CardcontentMobileSx
                : forgotpassword_style.CardcontentSx
            }
          >
            <Box display="flex" gap={0.5}>
              <HealthCirclesLogo />
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Health Circles
              </Typography>
            </Box>
            <Box sx={forgotpassword_style.formBoxSx}>
              <Typography variant="h5" sx={forgotpassword_style.titleSx}>
                Forgot Password
              </Typography>
              <Typography variant="body2" sx={forgotpassword_style.subtitleSx}>
                Provide us the registered email id to reset your password.
              </Typography>
            </Box>

            <Box sx={forgotpassword_style.inputGroupSx}>
              <Label
                rootStyle={forgotpassword_style.labelStyle}
                htmlFor="password"
              >
                Email
              </Label>
              <Input
                id="email"
                value={doctor?.email}
                onChange={(e) => {
                  changeState('email', e.target.value);
                }}
                isError={doctor?.error?.email?.length > 0 ? true : false}
                errorMessage={doctor?.error?.email}
                textFieldStyle={forgotpassword_style.inputFieldSx}
              />
            </Box>
            <Box sx={forgotpassword_style.buttonBox}>
              <Button
                fullWidth
                onClick={() => onGetLink()}
                buttonStyle={forgotpassword_style.buttonsStyle}
                loading={loading}
              >
                Get Link
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

ForgotPassword.propTypes = {
  className: PropTypes.string,
};
