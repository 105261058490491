import { useDoctorChatConsultation } from '@hc/store/doctor/chatConsultation';
import { Box, Button, Grid, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { ChatStatusCard } from '../../clinical/chatStatusCard';
import { doctorApproveModal_style } from './style';

function DoctorApproveModal(props) {
  const {
    className = '',
    modalType = '',
    rootStyle = {},
    handleDialogueClose,
    seenChatEndConfirmation,
    makeOffline,
    seenChatCancelConfirmation,
    startChat,
    isModalOpen,
    hideAppointmentCard = false,
    endedByName = '',
    ...rest
  } = props;

  const { doctorChatConsultationState } = useDoctorChatConsultation(
    (state) => ({
      doctorChatConsultationState: state.doctorChatConsultationState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { chatStatusData } = doctorChatConsultationState;

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    type,
  } = chatStatusData;

  return (
    <Box
      sx={{
        ...doctorApproveModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={doctorApproveModal_style.rootSx}>
        <Modal open={isModalOpen} onClose={handleDialogueClose}>
          <Box sx={doctorApproveModal_style.totalModalSx}>
            <Box sx={doctorApproveModal_style.totalMobileBoxSx}>
              <Box px={1.5}>
                <ChatStatusCard
                  hideAppointmentCard={hideAppointmentCard}
                  confirmed
                  noIconShow
                  message={
                    modalType === 'accept' ||
                    modalType === 'payment_initiated' ||
                    modalType === 'payment_completed'
                      ? 'You approved the request'
                      : modalType === 'reject'
                      ? 'You rejected the request'
                      : modalType === 'cancel'
                      ? 'Patient cancelled the request'
                      : modalType === 'end_by_patient'
                      ? `Chat ended by ${endedByName}`
                      : modalType === 'end_by_doctor'
                      ? `Chat ended by Dr. ${endedByName}`
                      : ''
                  }
                  subMessage={
                    modalType === 'accept'
                      ? 'Please be wait for patient payment'
                      : modalType === 'reject'
                      ? 'Hey, I think you are busy with consultation. If you are busy means, Please make your chat status to Offline!'
                      : modalType === 'payment_initiated'
                      ? 'Payment initiated by the patient. Please wait a while!'
                      : modalType === 'payment_completed'
                      ? 'Payment completed by patient. Please start the chat!'
                      : ''
                  }
                  type={type ?? ''}
                  profilePicture={patientProfilePicture ?? ''}
                  name={patientName ?? ''}
                  mobileNo={patientMobileNo ?? ''}
                  doctorChatConsultationAmount={
                    doctorChatConsultationAmount ?? ''
                  }
                  chatRequestedDate={chatRequestedDate ?? ''}
                  chatConsultationDuration={
                    chatConsultationDuration ?? '15 Mins'
                  }
                />

                {modalType === 'cancel' ? (
                  <Button
                    sx={doctorApproveModal_style.cancelbuttonSx}
                    onClick={() => seenChatCancelConfirmation()}
                    fullWidth
                  >
                    {` Okey, Let's wait for someother chat request!`}
                  </Button>
                ) : modalType === 'accept' ||
                  modalType === 'payment_initiated' ||
                  modalType === 'payment_completed' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pb: 2,
                      gap: 2,
                    }}
                  >
                    <Button
                      sx={{ textTransform: 'none', cursor: 'pointer' }}
                      onClick={() => startChat()}
                      // fullWidth
                      variant="contained"
                      disabled={
                        modalType === 'accept' ||
                        modalType === 'payment_initiated'
                      }
                    >
                      Start your chat
                    </Button>
                  </Box>
                ) : modalType === 'reject' ? (
                  <Grid container sx={{ display: 'flex', p: 2 }} columnSpacing={2}>
                    <Grid item xs={6}>
                      {' '}
                      <Button
                        sx={doctorApproveModal_style.DeletebuttonSx}
                        onClick={() => makeOffline()}
                      >
                        Make Offline
                      </Button>
                    </Grid>
                    <Grid item xs={6} justifyContent='end' display="flex">
                      <Button
                        sx={doctorApproveModal_style.cancelbuttonSx}
                        onClick={() => handleDialogueClose()}
                      >
                        No, Thanks
                      </Button>
                    </Grid>
                  </Grid>
                ) : // <Box sx={{ display: 'flex', p: 2, gap: 2 }}>

                // </Box>
                modalType === 'end_by_patient' ||
                  modalType === 'end_by_doctor' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      p: 2,
                      gap: 2,
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      sx={doctorApproveModal_style.cancelbuttonSx}
                      onClick={() => seenChatEndConfirmation()}
                    >
                      Okey, Close
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

DoctorApproveModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { DoctorApproveModal };
