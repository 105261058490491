import { rxAuditRoutes } from '@hc/routes';
import { PageNotFound } from '@hc/ui/components';
import { DoctorRootLayout } from '@hc/ui/components/doctor';
import Summary from '@pages//summary';
import { DoctorOnboarding } from '@pages/createAccount';
import ErrorBoundary from '@pages/errorBoundary';
import ForgotPassword from '@pages/forgotpassword';
import Home from '@pages/home';
import Onboarding from '@pages/onboarding';
import DoctorProfile from '@pages/profile';
import ResetPassword from '@pages/resetPassword';
import SignIn from '@pages/signin';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrivateRouter } from './privateRouter';
import ReportViewSummary from '../pages/reportViewSummary';
import ViewReportPage from '../pages/ViewSummaryReports';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRouter>
        <DoctorRootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      // onboarding
      {
        path: rxAuditRoutes?.createaccount,
        element: <DoctorOnboarding />,
      },
      {
        path: rxAuditRoutes?.signin,
        element: <SignIn />,
      },
      {
        path: rxAuditRoutes?.onboarding,
        element: <Onboarding />,
      },
      {
        path: rxAuditRoutes?.forgotpassword,
        element: <ForgotPassword />,
      },
      {
        path: rxAuditRoutes?.resetpassword,
        element: <ResetPassword />,
      },
      // {
      //   path: doctorRoutes?.rxgroup,
      //   element: <RxGroup />,
      // },
      // {
      //   path: doctorRoutes?.viewDrug,
      //   element: <ViewDrug />,
      // },
      {
        path: rxAuditRoutes?.profile,
        element: <DoctorProfile />,
      },
      {
        path: rxAuditRoutes?.viewreports,
        element: <ReportViewSummary />,
      },
      {
        path: rxAuditRoutes?.reportspage,
        element: <ViewReportPage />,
      },
      {
        path: rxAuditRoutes?.summary,
        element: <Summary />,
      },
      // {
      //   path: doctorRoutes?.availability,
      //   element: <DoctorAvailability />,
      // },
      {
        path: rxAuditRoutes?.home,
        element: <Home />,
      },

      // {
      //   path: doctorRoutes?.googleintegeration,
      //   element: <GoogleIntegeration />,
      // },
      // {
      //   path: doctorRoutes?.referralsuccess,
      //   element: <ReferralSuccess />,
      // },
      // {
      //   path: doctorRoutes?.Prescription,
      //   element: <Prescription />,
      // },
    ],
  },
  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;
